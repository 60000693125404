<template>
  <div class="admin_login">
    <div class="u-container">
      <div class="c-hd">
        <a class="c-logo" href="/home">
          <img src="../../assets/images/aboutus_log.gif" alt="" />
        </a>
      </div>
      <div class="c-bd">
        <el-row :gutter="50">
          <el-col :span="6">
            <a
              href="https://serfoo.com/school_admin/login?id=1"
              target="_blank"
            >
              <div class="c-item">十二方学校招生报名<br />录入口</div>
            </a>
          </el-col>
          <el-col :span="6">
            <a
              href="https://serfoo.com/school_admin/login?id=2"
              target="_blank"
            >
              <div class="c-item">十二方学校小程序<br />控制面板</div>
            </a>
          </el-col>
          <el-col :span="6">
            <a
              href="https://serfoo.com/school_admin/login?id=3"
              target="_blank"
            >
              <div class="c-item">十二方保安招聘人员<br />录入口</div>
            </a>
          </el-col>
          <el-col :span="6">
            <a href="https://serfoo.com/adminapi/login?id=2" target="_blank">
              <div class="c-item">SERFOO企业介绍<br />登录口</div>
            </a>
          </el-col>
          <el-col :span="6">
            <a href="https://serfoo.com/career_admin/" target="_blank">
              <div class="c-item">十二方职业介绍<br />登录口</div>
            </a>
          </el-col>
        </el-row>
      </div>
      <div class="c-footer">
        <div class="f-main">
          <div class="m-item">
            <a href="/home">主页</a>
          </div>
          <div class="m-item">
            <a href="/home/about?id=38">十二产业</a>
          </div>
          <div class="m-item">
            <a href="/home/admin_login">管理者登录</a>
          </div>
          <div class="m-item">
            <a href="https://serfoo.com/school/pc/home" target="_blank"
              >职业介绍</a
            >
          </div>
          <div class="m-txt">
            <a href="https://beian.miit.gov.cn/" target="_blank"
              >湘ICP备2021002198号-1</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "about",
    data() {
      return {

      }
    },
    created() {

    },
    methods: {

    },
  }
</script>

<style lang="less" scoped>
.el-col-6 {
  margin-bottom: 30px;
}

.admin_login {
  background: #fff;

  .u-container {
    width: 1200px;
    margin: 0px auto;
    min-height: 800px;
    box-sizing: border-box;

    .c-hd {
      .c-logo {
        width: 1200px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .c-bd {
      border: 1px solid #ccc;
      border-radius: 20px;
      max-height: 600px;
      min-height: 400px;
      padding: 50px;

      a {
        color: #000;
        font-size: 16px;

        .c-item {
          background-color: #e6e6e6;
          border-radius: 20px;
          text-align: center;
          line-height: 1.6;
          padding: 30px 45px;
        }
      }
    }

    .c-footer {
      width: 1200px;
      margin: 0 auto;
      margin-top: 40px;
      border-top: 40px solid #eee;
      font-size: 14px;
      text-align: center;

      .f-main {
        padding: 20px 0px;

        .m-item {
          height: 20px;
          box-sizing: border-box;
          line-height: 20px;
          border-right: 1px solid #ddd;
          text-align: center;
          padding: 0px 10px;
          display: inline-block;
        }

        .m-item:last-child {
          border-right: none;
        }

        .m-txt {
          line-height: 40px;
          text-align: center;
          font-size: 13px;
        }
      }
    }
  }
}
</style>